<template>
    <div>
        <!-- <v-btn small color="darkgreen" class="white--text" outline @click="show = true">Update Info</v-btn> -->
        <v-dialog v-model="show" persistent scrollable max-width="850px">
            <v-card>
                <v-card-title>
                    <img width="100" class="mb-1 mt-1" src="../assets/quaro_final_rgb.svg" />
                    <v-btn right absolute @click="$emit('close')" class="mb-1" icon><v-icon color="primary">mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="welcommessage px-7">
                    <h1 class="dialogtitle my-5">{{ welcome_message.title }}</h1>

                    <h3 class="mb-2 ml-1">We are excited to introduce you to the new features of Quaro. In addition to some design and performance adjustments, you can expect useful new features:</h3>

                    <v-list-item two-line class="pt-2">
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-1-circle</v-icon>
                                    Questions-Modul
                                </h4>
                            </v-list-item-title>
                            <p class="subtext">
                                In the Questions-Module, in addition to classic questions, prepositions and comparative words are now retrieved. For a simplified view and better sorting, we tag the
                                keywords with the corresponding tags.
                            </p>
                        </v-list-item-content>
                    </v-list-item>

                    <div class="img-con mb-10 flex">
                        <img width="750" class="mx-auto" src="../assets/updatemeldung/questions_update_msg.png" />
                    </div>
                    <!-- VIDEO -->

                    <!-- <div class="video-block">
                        <iframe
                            width="auto"
                            src="https://www.youtube.com/embed/_znzNQE3nyA"
                            title="Quaro - New Features"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div> -->

                    <v-list-item two-line class="pt-1">
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-2-circle</v-icon>
                                    Keyword-Trends
                                </h4>
                            </v-list-item-title>
                            <p class="subtext pt-1">In addition to the normal overview of keywords, there is now the overview <span class="bold">Keyword Trends"</span>.</p>
                            <p class="subtext pt-2">
                                Here the keywords are sorted by the Short Term Trend to <span class="bold">identify currently trending keywords</span>. Additionally, the keywords can be sorted by
                                <span class="bold">monthly and yearly search volume change </span>, either by absolute or percentage change. The view for keyword trends can be changed in the tab at
                                the top left in the keyword table.
                            </p>
                        </v-list-item-content>
                    </v-list-item>

                    <div class="img-con mb-10 flex">
                        <img width="750" class="mx-auto" src="../assets/updatemeldung/trend_update_msg.png" />
                    </div>
                    <!-- VIDEO -->

                    <!-- <div class="video-block">
                        <iframe
                            width="auto"
                            src="https://www.youtube.com/embed/UtEZfj0P4tw"
                            title="Quaro - SERP-Preview"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div> -->

                    <v-list-item two-line class="pt-1">
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-3-circle</v-icon>
                                    Peak/Season
                                </h4>
                            </v-list-item-title>
                            <p class="subtext pt-1">
                                The Keyword Table gets two new columns: <span class="bold">Peak Month</span> and <span class="bold">Season</span>. The columns indicate which month of the year was the
                                strongest and during which period of the year the keyword was searched for more than average and therefore has a season. The columns are<span class="bold">
                                    hidden by default and can be shown in the keyword table via Customize</span
                                >.
                            </p>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line class="pt-1">
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-4-circle</v-icon>
                                    Save created columns
                                </h4>
                            </v-list-item-title>
                            <p class="subtext pt-1">
                                Columns created by a user can now be saved as a favorite. This way, columns created once <span class="bold">can be reused in every module</span>.
                                <!-- The columns saved as a
                                favorite can be selected and <span class="bold">used by all users of the company</span>. -->
                            </p>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line class="pt-1">
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-5-circle</v-icon>
                                    Keyword saved
                                </h4>
                            </v-list-item-title>
                            <p class="subtext pt-1">Besides each keyword, it is now displayed whether the keyword is saved in the clipboard or in one or multiple collections.</p>
                        </v-list-item-content>
                    </v-list-item>

                    <div class="img-con mb-10 flex">
                        <img width="750" class="mx-auto" src="../assets/updatemeldung/kwsaved__update_msg.png" />
                    </div>

                    <v-list-item two-line class="pt-1">
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-6-circle</v-icon>
                                    Enter domain in SERP Preview
                                </h4>
                            </v-list-item-title>
                            <p class="subtext pt-1">To check the position of a website in the SERPs, a domain can be entered in the SERP Preview.</p>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line class="pt-1">
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-7-circle</v-icon>
                                    Inviting users & company Domain
                                </h4>
                            </v-list-item-title>
                            <p class="subtext pt-1">
                                New users can now be added by the admin directly through "Settings". In addition, the admin can register a company domain and change it, so that other users of the
                                company can register independently.
                            </p>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line class="pt-1">
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-8-circle</v-icon>
                                    User settings
                                </h4>
                            </v-list-item-title>
                            <p class="subtext pt-1">
                                Settings made by the user are now saved for the next session. This includes general chart settings, whether the user is in advanced or standard mode, dark mode,
                                sorting, and the number of rows per page on the collections page.
                            </p>
                        </v-list-item-content>
                    </v-list-item>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="" @click="$emit('close')" dark color="primary">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "UpdateCard",
        props: {
            show: Boolean
        },
        data() {
            return {
                showMessage: localStorage.message_read ? false : true,
                welcome_message: {
                    title: "The January update is here 🎉",
                    firstp: "Seit dem 19.05.2022 werden von Google fehlerhafte Daten ausgeliefert. Es wird im Modul Google Search Volume für alle Locations das exakt gleiche Suchvolumen ausgegeben!",
                    secondp:
                        "Der Name Quaro kommt vom lateinischen Verb Quaerere und steht unter anderem für Suchen, Fragen und Erforschen. Wir sind der Überzeugung, dass der neue Name und das neue Logo uns besser beschreiben und einfach besser passen."
                },
                dialog: true,
                user: localStorage.username ? localStorage.username : "undefined",
                plan: localStorage.plan ? localStorage.plan : "undefined"
            }
        },
        computed: {
            decryptedName() {
                return this.CryptoJS.AES.decrypt(this.user, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
            },
            decryptedPlan() {
                return this.CryptoJS.AES.decrypt(this.plan, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
            }
        },
        methods: {
            closeWelcomeMessage() {
                localStorage.setItem("message_read", true)
                this.showMessage = false
            }
        }
    }
</script>

<style>
    .bold {
        font-weight: bold;
    }
    .welcommessage {
        font-family: Nunito;
        font-weight: 100;
        padding-bottom: 0px;
    }
    #welcomecard {
        padding: 15px;
        color: white;
        background: linear-gradient(56deg, rgba(28, 237, 178, 1) 0%, rgba(0, 159, 138, 1) 0%, rgb(28, 237, 178) 100%);
    }
    .welcometitle {
        font-family: Nunito;
        font-weight: 100;
        text-align: center;
    }
    .subtitle {
        text-align: center;
        font-family: Nunito;
    }
    .chiptext {
        font-weight: bold;
        color: var(--v-primary-base);
    }
    .sitoquaro div {
        display: inline;
        padding: 10px;
    }
    .sitoquaro {
        text-align: center;
        margin-left: auto;
    }
    .video-block {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        overflow: hidden;
        max-width: 100%;
        height: auto;
    }

    .video-block iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 15px;
    }
    .subtext {
        color: rgb(87, 87, 87);
    }

    img {
        border-radius: 10px;
    }
</style>
